//健康监护日数据js
import { GetHealthDailyDataV2 } from "../../api/homeGuardianship";
export const Mixins = {
    data() {
        return {
            healthSituation: {},
            //0:低心率,1:高心率,2:呼吸过缓,3:呼吸过速,4:呼吸暂停,6:离床超时,
            waringType0: [],
            waringType1: [],
            waringType2: [],
            waringType3: [],
            waringType4: [],
            waringType6: [],
            xData1: [],
            xData5: [],
            deviceColum: null,
            deviceOptions: {
                tooltip: {
                    trigger: "axis",

                    formatter: function (params) {

                        var axisValueLabel = params[0].axisValueLabel;

                        var value = params[0].value;
                        let itemTxt = ''
                        if (value == '1') {
                            itemTxt = '离床'
                        }
                        if (value == '2') {
                            itemTxt = '离床'
                        }
                        if (value == '3') {
                            itemTxt = '在床'
                        }
                        return params[0].marker + axisValueLabel + itemTxt;
                    },

                },

                legend: {
                    data: [],
                },

                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "12%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: [],
                },

                yAxis: {
                    type: "value",
                    min: 0, // Y轴的最小值
                    max: 4, // Y轴的最大值

                },

                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    },

                    {
                        type: 'inside',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    }

                ],
                series: [
                    {
                        type: "line",
                        stack: "Total",
                        data: [],
                    },

                ],
            },
            warningColum: null,
            warningOptions: {
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    data: ['离床超时', '呼吸暂停', '呼吸过速', '呼吸过缓', '心率过速', '心率过缓'],
                },

                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "12%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: [],
                },

                yAxis: {
                    type: "value",
                },

                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    },

                    {
                        type: 'inside',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    }

                ],
                series: [
                    {
                        name: "离床超时",
                        type: "scatter",
                        stack: "Total",
                        data: [],
                    },
                    {
                        name: "呼吸暂停",
                        type: "scatter",
                        stack: "Total",
                        data: [],
                    },
                    {
                        name: "呼吸过速",
                        type: "scatter",
                        stack: "Total",
                        data: [],
                    },
                    {
                        name: "呼吸过缓",
                        type: "scatter",
                        stack: "Total",
                        data: [],
                    },
                    {
                        name: "心率过速",
                        type: "scatter",
                        stack: "Total",
                        data: [],
                    },
                    {
                        name: "心率过缓",
                        type: "scatter",
                        stack: "Total",
                        data: [],
                    },

                ],
            },
            //体动分布
            moveColum: null,
            moveOptions: {
                tooltip: {
                    trigger: "axis",
                },
                color: ['#91cc75'],
                legend: {
                    data: [],
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "12%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: [],
                },

                yAxis: {
                    type: "value",
                },
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    },

                    {
                        type: 'inside',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    }

                ],
                series: [
                    {
                        type: "bar",
                        stack: "Total",
                        data: [],
                    },
                ]

            },

            //心率
            heartColum: null,
            heartOptions: {
                tooltip: {
                    trigger: "axis",
                },
                color: ['#eabd09'],
                legend: {
                    data: [],
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "12%",
                    containLabel: true,
                },

                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: [],
                },

                yAxis: {
                    type: "value",
                },
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    },

                    {
                        type: 'inside',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    }

                ],
                series: [
                    {
                        type: "line",
                        stack: "Total",
                        data: [],
                        markPoint: {
                            data: [
                                {
                                    type: 'max', name: 'Max', label: {
                                        show: true,
                                        color: '#ffffff'
                                    },
                                    itemStyle: { color: '#198bed' }
                                },
                                {
                                    type: 'min', name: 'Min', label: {
                                        show: true,
                                        color: '#ffffff'
                                    },
                                    itemStyle: { color: '#eda025' }
                                }
                            ]
                        },
                    },
                ]

            },
            //呼吸分布
            breathtColum: null,
            breathOptions: {
                tooltip: {
                    trigger: "axis",
                },
                color: ['#f47b31'],
                legend: {
                    data: [],
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "12%",
                    containLabel: true,
                },

                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: [],
                },

                yAxis: {
                    type: "value",
                },
                dataZoom: [
                    {
                        type: 'slider',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    },

                    {
                        type: 'inside',
                        xAxisIndex: 0,
                        filterMode: 'none'
                    }

                ],
                series: [
                    {
                        type: "line",
                        stack: "Total",
                        data: [],
                        markPoint: {
                            data: [
                                {
                                    type: 'max', name: 'Max', label: {
                                        show: true,
                                        color: '#ffffff'
                                    },
                                    itemStyle: { color: '#ec5749' }
                                },
                                {
                                    type: 'min', name: 'Min', label: {
                                        show: true,
                                        color: '#ffffff'
                                    },
                                    itemStyle: { color: '#55d6f4' }
                                }
                            ]
                        },
                    },
                ]

            }


        }
    },
    methods: {
        //获取数据
        getHealthDailyDataV2() {
            GetHealthDailyDataV2({ day: this.timeData }).then((res) => {
                console.log(res)
                let data = res.result
                this.healthSituation = data.healthSituation;
                if (data.waringRecords && data.waringRecords.length > 0) {
                    data.waringRecords.forEach(element => {

                        if (element.waringType == 0) {
                            this.waringType0.push(element)
                        }
                        if (element.waringType == 1) {
                            this.waringType1.push(element)
                        }
                        if (element.waringType == 2) {
                            this.waringType2.push(element)
                        }
                        if (element.waringType == 3) {
                            this.waringType3.push(element)
                        }
                        if (element.waringType == 4) {
                            this.waringType4.push(element)
                        }
                        if (element.waringType == 6) {
                            this.waringType6.push(element)
                        }


                    });
                }
                this.deviceColum = this.$echarts.init(this.$refs.deviceColum);
                //图形数据
                let ChartData = data.ChartData
                this.xData1 = ChartData.xData1;
                this.xData5 = ChartData.xData5;

                //状态分布数据
                this.deviceOptions.xAxis.data = ChartData.xData1;

                this.deviceOptions.series[0].data = ChartData.deviceStateDetails




                this.deviceColum.setOption(this.deviceOptions);

                //异常状态
                this.warningOptions.xAxis.data = ChartData.xData1;
                this.warningOptions.series[0].data = ChartData.warningDetails.leaveBed
                this.warningOptions.series[1].data = ChartData.warningDetails.apneaCount
                this.warningOptions.series[2].data = ChartData.warningDetails.breathFast
                this.warningOptions.series[3].data = ChartData.warningDetails.breathSlow
                this.warningOptions.series[4].data = ChartData.warningDetails.heartFast
                this.warningOptions.series[5].data = ChartData.warningDetails.heartSlow
                //体动分布
                this.moveOptions.xAxis.data = ChartData.xData1;
                let moveDetails = ChartData.moveDetails.map(item => {
                    return [item[0], item[1]]
                })
                this.moveOptions.series[0].data = moveDetails

                //心率
                this.heartOptions.xAxis.data = ChartData.xData5;
                this.heartOptions.series[0].data = ChartData.heartDetails

                //呼吸
                this.breathOptions.xAxis.data = ChartData.xData5;
                this.breathOptions.series[0].data = ChartData.breathDetails

            })

        }

    }
}  