<!--
居家监护
-->
<template>
  <div class="mainBox">
    <div class="shipTitle">
      <div class="changTitle">
        <div
          class="changeItme"
          :class="changeIndex == 1 ? 'changeItmeActive' : ''"
          @click="changeTitle(1)"
        >
          健康监护
        </div>
        <div
          class="changeItme"
          :class="changeIndex == 2 ? 'changeItmeActive' : ''"
          @click="changeTitle(2)"
        >
          安全监护
        </div>
      </div>
      <div class="timeBox" v-if="changeIndex == 1">
        <el-date-picker
          v-model="timeData"
          type="date"
          placeholder="选择日期"
          class="timeBox"
          value-format="yyyy-MM-dd"
          @change="dailyChangeTime"
          :clearable="false"
        >
        </el-date-picker>
      </div>
      <div class="timeBox" v-if="changeIndex == 2">
        <el-date-picker
          :clearable="false"
          v-model="timeData2"
          type="date"
          placeholder="选择日期"
          class="timeBox"
          value-format="yyyy-MM-dd"
          @change="jhChangeTime"
        >
        </el-date-picker>
      </div>
    </div>
    <!--健康监护-->
    <div class="shipMain">
      <div v-show="changeIndex == 1" class="healthBox">
        <!-- 视频播放 -->
        <div class="videoBox" v-if="setCameraInfo.id" @click="getVedioToken()">
          <img src="@/assets/img/lease/video.png" alt="" class="videoIcon" />
          <div class="videoTxt">视频监控</div>
        </div>
        <div class="shipNav">
          <img
            src="@/assets/img/homeGuardianship/icon_Device.png"
            alt=""
            class="titleIcon"
          />
          <span class="titleName">设备数据</span>
        </div>
        <!-- 设备数据列表 -->
        <div class="deviceList">
          <div class="deviceItem devicebg1">
            <div class="deviceItemLeft">
              <p class="leftTitle">平均心率</p>
              <p class="leftCount">
                <span class="countNum">{{ healthSituation.avgHeart }}</span
                ><span class="countUnit">次/分</span>
              </p>
            </div>
            <div class="deviceItemRight">
              <img
                src="@/assets/img/homeGuardianship/de_heart.png"
                alt=""
                class="deviceIcon"
              />
            </div>
          </div>

          <div class="deviceItem devicebg2">
            <div class="deviceItemLeft leftColor">
              <p class="leftTitle">平均呼吸率</p>
              <p class="leftCount">
                <span class="countNum">{{ healthSituation.avgBreath }}</span
                ><span class="countUnit">次/分</span>
              </p>
            </div>
            <div class="deviceItemRight">
              <img
                src="@/assets/img/homeGuardianship/de_breathe.png"
                alt=""
                class="deviceIcon"
              />
            </div>
          </div>
          <div class="deviceItem devicebg3">
            <div class="deviceItemLeft leftColor2">
              <p class="leftTitle">体动总次数</p>
              <p class="leftCount">
                <span class="countNum">{{ healthSituation.moveCount }}</span
                ><span class="countUnit">次</span>
              </p>
            </div>
            <div class="deviceItemRight">
              <img
                src="@/assets/img/homeGuardianship/de_move.png"
                alt=""
                class="deviceIcon"
              />
            </div>
          </div>
        </div>
        <!-- 数据预警及处理 -->
        <div class="shipNav navMargin">
          <img
            src="@/assets/img/homeGuardianship/icon_Data.png"
            alt=""
            class="titleIcon"
          />
          <span class="titleName">数据预警及处理</span>
        </div>
        <div class="earlyList">
          <div class="earlyItme">
            <div class="earlyBox earlyBoxR">
              <div class="earlyIcon earlyColor">
                <img
                  src="@/assets/img/homeGuardianship/earlyIcon.png"
                  class="earlyImg"
                />
              </div>
              <div class="earlyTitle">
                <div class="earlyTxt">心率过速</div>
                <div class="earlyLabel">{{ waringType1.length }}次</div>
              </div>
            </div>
            <div class="earlyBox">
              <div class="earlyIcon earlyColor">
                <img
                  src="@/assets/img/homeGuardianship/earlyIcon.png"
                  class="earlyImg"
                />
              </div>
              <div class="earlyTitle">
                <div class="earlyTxt">心率过缓</div>
                <div class="earlyLabel">{{ waringType0.length }}次</div>
              </div>
            </div>
          </div>
          <div class="earlyItme">
            <div class="earlyBox earlyBoxR">
              <div class="earlyIcon earlyColor2">
                <img
                  src="@/assets/img/homeGuardianship/earlyIcon1.png"
                  class="earlyImg"
                />
              </div>
              <div class="earlyTitle">
                <div class="earlyTxt">呼吸过缓</div>
                <div class="earlyLabel">{{ waringType2.length }}次</div>
              </div>
            </div>
            <div class="earlyBox">
              <div class="earlyIcon earlyColor2">
                <img
                  src="@/assets/img/homeGuardianship/earlyIcon1.png"
                  class="earlyImg"
                />
              </div>
              <div class="earlyTitle">
                <div class="earlyTxt">呼吸过速</div>
                <div class="earlyLabel">{{ waringType3.length }}次</div>
              </div>
            </div>
          </div>
          <div class="earlyItme2">
            <div class="earlyBox2">
              <div class="earlyIcon earlyColor4">
                <img
                  src="@/assets/img/homeGuardianship/earlyIcon3.png"
                  class="earlyImg"
                />
              </div>
              <div class="earlyTitle">
                <div class="earlyTxt">呼吸暂停</div>
                <div class="earlyLabel">{{ waringType4.length }}次</div>
              </div>
            </div>
          </div>
          <div class="earlyItme2">
            <div class="earlyBox2">
              <div class="earlyIcon earlyColor3">
                <img
                  src="@/assets/img/homeGuardianship/earlyIcon2.png"
                  class="earlyImg"
                />
              </div>
              <div class="earlyTitle">
                <div class="earlyTxt">夜间离床超时</div>
                <div class="earlyLabel">{{ waringType6.length }}次</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 数据分布 -->
        <div class="shipNav navMargin">
          <img
            src="@/assets/img/homeGuardianship/icon_state.png"
            alt=""
            class="titleIcon"
          />
          <span class="titleName">数据分布</span>
        </div>
        <div class="barBox">
          <div class="barTitle">
            <div
              v-for="(item, index) in barList"
              :key="index"
              :class="index == barActiveIndex ? 'barActive' : ''"
              @click="changeBar(index)"
            >
              {{ item }}
            </div>
          </div>
          <!-- 柱状图 -->
          <div
            class="barChart"
            ref="deviceColum"
            v-show="barActiveIndex == 0"
          ></div>
          <div
            class="barChart"
            ref="warningColum"
            v-show="barActiveIndex == 1"
          ></div>
          <div
            class="barChart"
            ref="moveColum"
            v-show="barActiveIndex == 2"
          ></div>
          <div
            class="barChart"
            ref="heartColum"
            v-show="barActiveIndex == 3"
          ></div>
          <div
            class="barChart"
            ref="breathtColum"
            v-show="barActiveIndex == 4"
          ></div>
        </div>
      </div>
      <!-- 安全监护 -->
      <div v-show="changeIndex == 2" class="healthBox">
        <!-- 安全预警次数 -->
        <div class="shipNav">
          <img
            src="@/assets/img/homeGuardianship/icon_safety.png"
            alt=""
            class="titleIcon"
          />
          <span class="titleName">安全预警次数</span>
        </div>

        <div class="deviceList">
          <div class="deviceItem devicebg4" @click="openTable(sosWork)">
            <div class="deviceItemLeft leftColor3">
              <p class="leftTitle">SOS报警次数</p>
              <p class="leftCount">
                <span class="countNum">{{ sosWork.length }}</span
                ><span class="countUnit">次</span>
              </p>
            </div>
            <div class="deviceItemRight">
              <img
                src="@/assets/img/homeGuardianship/de_sofa.png"
                alt=""
                class="deviceIcon2"
              />
            </div>
          </div>
          <!-- <div class="deviceItem devicebg4" @click="openTable(waterWork)">
            <div class="deviceItemLeft leftColor3">
              <p class="leftTitle">水浸报警次数</p>
              <p class="leftCount">
                <span class="countNum">{{ waterWork.length }}</span
                ><span class="countUnit">次</span>
              </p>
            </div>
            <div class="deviceItemRight">
              <img
                src="@/assets/img/homeGuardianship/icon_Waterimmersion.png"
                alt=""
                class="deviceIcon2"
              />
            </div>
          </div> 
          <div class="deviceItem devicebg4" @click="openTable(smokesWork)">
            <div class="deviceItemLeft leftColor3">
              <p class="leftTitle">烟感报警次数</p>
              <p class="leftCount">
                <span class="countNum">{{ smokesWork.length }}</span
                ><span class="countUnit">次</span>
              </p>
            </div>
            <div class="deviceItemRight">
              <img
                src="@/assets/img/homeGuardianship/icon_smoke.png"
                alt=""
                class="deviceIcon2"
              />
            </div>
          </div>
          <div class="deviceItem devicebg4" @click="openTable(gasWork)">
            <div class="deviceItemLeft leftColor3">
              <p class="leftTitle">燃气报警次数</p>
              <p class="leftCount">
                <span class="countNum">{{ gasWork.length }}</span
                ><span class="countUnit">次</span>
              </p>
            </div>
            <div class="deviceItemRight">
              <img
                src="@/assets/img/homeGuardianship/icon_gasalarm.png"
                alt=""
                class="deviceIcon2"
              />
            </div>
          </div>-->
        </div>

        <!-- 安全预警服务 -->
        <div class="shipNav navMargin">
          <img
            src="@/assets/img/homeGuardianship/icon_safety.png"
            alt=""
            class="titleIcon"
          />
          <span class="titleName">安全预警服务</span>
        </div>
        <div class="safeList">
          <div class="safeItem">
            <div>
              <img
                src="@/assets/img/homeGuardianship/safeIcon.png"
                alt=""
                class="safeIcon"
              />
            </div>
            <div class="safeTitle">
              <div class="earlyTxt">SOS报警</div>
              <div class="earlyLabel">{{ sosWorkSuccess.length }}次</div>
            </div>
          </div>
          <!-- 
          <div class="safeItem">
            <div>
              <img
                src="@/assets/img/homeGuardianship/icon_Waterimmersion.png"
                alt=""
                class="safeIcon"
              />
            </div>
            <div class="safeTitle">
              <div class="earlyTxt">水浸报警</div>
              <div class="earlyLabel">{{ waterWorkSuccess.length }}次</div>
            </div>
          </div>

          <div class="safeItem">
            <div>
              <img
                src="@/assets/img/homeGuardianship/icon_smoke.png"
                alt=""
                class="safeIcon"
              />
            </div>
            <div class="safeTitle">
              <div class="earlyTxt">烟感报警</div>
              <div class="earlyLabel">{{ smokesWorkSuccess.length }}次</div>
            </div>
          </div>
          <div class="safeItem">
            <div>
              <img
                src="@/assets/img/homeGuardianship/icon_gasalarm.png"
                alt=""
                class="safeIcon"
              />
            </div>
            <div class="safeTitle">
              <div class="earlyTxt">燃气报警</div>
              <div class="earlyLabel">{{ gasWorkSuccess.length }}次</div>
            </div>
          </div> -->
        </div>

        <!-- 安全预警次数 -->
        <div class="shipNav navMargin">
          <img
            src="@/assets/img/homeGuardianship/icon_state.png"
            alt=""
            class="titleIcon"
          />
          <span class="titleName">状态分布</span>
        </div>
        <div class="barBox">
          <!-- 柱状图 -->
          <div class="barChart" ref="statusBar"></div>
        </div>
      </div>
    </div>

    <!-- 弹出层 -->
    <el-dialog title="" :visible.sync="showTabele" width="1000px">
      <div class="tableBox">
        <el-table
          :data="tableData"
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="workCode" label="工单编号" width="180">
          </el-table-column>
          <el-table-column prop="content" label="工单内容" width="180">
          </el-table-column>

          <el-table-column prop="mobile" label="手机" width="180">
          </el-table-column>
          <el-table-column prop="telTime" label="来电时间" width="180">
          </el-table-column>
          <el-table-column prop="address" label="地址" width="180">
          </el-table-column>
          <el-table-column prop="result" label="处理结果" width="180">
          </el-table-column>
          <el-table-column prop="remark" label="备注" width="180">
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="180">
          </el-table-column>

          <el-table-column label="工单状态">
            <template slot-scope="scope">
              <div
                class="statusBox statusBoxColor1"
                v-if="scope.row.orderState == 0"
              >
                待办
              </div>
              <div
                class="statusBox statusBoxColor2"
                v-if="scope.row.orderState == 1"
              >
                正在处理
              </div>
              <div
                class="statusBox statusBoxColor3"
                v-if="scope.row.orderState == 2"
              >
                完成
              </div>
              <div
                class="statusBox statusBoxColor1"
                v-if="scope.row.orderState == 3"
              >
                转交
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 视频播放 -->

    <el-dialog title="" :visible.sync="showCamera" width="1000px">
      <div class="cameraBox" id="video-container"></div>
    </el-dialog>
  </div>
</template>
<script>
import { tool } from "../../common/tool";
import EZUIKit from "ezuikit-js";
import {
  GetSOSWorkOrder,
  GetWaterWorkOrder,
  GetSmokeWorkOrder,
  GetGasWorkOrder,
  CountSOSWorkOrderOfDays,
  GetUserDevice,
  GetEquipmentVedioToken,
  CountWaterWorkOrderOfDays,
  CountSmokeWorkOrderOfDays,
  CountGasWorkOrderOfDays,
} from "../../api/homeGuardianship";
//混入日数据js
import { Mixins } from "./dailyData";

export default {
  mixins: [Mixins],
  components: {},
  data() {
    return {
      showCamera: false,
      showTabele: false,
      tableData: [],
      timeData: "",
      timeData2: "",
      changeIndex: 1,
      barActiveIndex: 0,
      barList: ["状态分布", "异常分布", "体动分布", "心率分布", "呼吸分布"],

      statusColum: null,
      statusOptions: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          // data: ["SOS报警", "水浸报警", "烟感报警", "燃气报警"],
          data: ["SOS报警"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "SOS报警",
            type: "line",
            stack: "Total",
            data: [],
          },
          /*
          {
            name: "水浸报警",
            type: "line",
            stack: "Total",
            data: [],
          },
          {
            name: "烟感报警",
            type: "line",
            stack: "Total",
            data: [],
          },
          {
            name: "燃气报警",
            type: "line",
            stack: "Total",
            data: [],
          },*/
        ],
      },

      sosWork: [],
      sosWorkSuccess: [],
      waterWork: [],
      waterWorkSuccess: [],
      smokesWork: [],
      smokesWorkSuccess: [],
      gasWork: [],
      gasWorkSuccess: [],
      day: 30,
      showLine2: true,
      setCameraInfo: {}, //视频信息
    };
  },
  created() {
    this.timeData = tool.getNowDate();
    this.timeData2 = tool.getNowDate();
  },

  mounted: function () {
    //this.initChart();
    this.getHealthDailyDataV2();
    this.getCamera();
  },
  destroyed: function () {},
  computed: {},
  watch: {},
  methods: {
    getCamera() {
      let cameraId = ["4c80099a-bb1d-4b10-81bd-481680e63834"];
      GetUserDevice({ bizType: "2" }).then((res) => {
        let data = res.result;
        if (data && data.length > 0) {
          data.forEach((element) => {
            //判断摄像头
            if (cameraId.includes(element.equipmentID)) {
              if (element.isSetup == 1) {
                this.setCameraInfo = element;
              }
            }
          });
        }
      });
    },
    getVedioToken() {
      GetEquipmentVedioToken(this.setCameraInfo.id).then((res) => {
        if (!res.result.playUrl || !res.result.token) {
          this.$message.error("地址或token有误！");

          return false;
        }
        this.palyVideo(res.result.token, res.result.playUrl);
      });
    },
    palyVideo(accessToken, url) {
      this.showCamera = true;
      new EZUIKit.EZUIKitPlayer({
        id: "video-container", // 视频容器ID
        accessToken: accessToken,
        url: url,
      });
    },

    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "double-row";
      }
      return "";
    },
    openTable(tableOption) {
      this.showTabele = true;
      this.tableData = tableOption;
    },
    changeTitle(index) {
      this.changeIndex = index;
      if (index == 2) {
        if (this.showLine2) {
          this.getInfo();
          this.initChart2();
        }
      }
    },
    changeBar(index) {
      this.barActiveIndex = index;
      if (index == 1) {
        this.$nextTick(() => {
          this.warningColum = this.$echarts.init(this.$refs.warningColum);
          this.warningColum.setOption(this.warningOptions);
        });
      }
      if (index == 2) {
        this.$nextTick(() => {
          this.moveColum = this.$echarts.init(this.$refs.moveColum);
          this.moveColum.setOption(this.moveOptions);
        });
      }
      if (index == 3) {
        this.$nextTick(() => {
          this.heartColum = this.$echarts.init(this.$refs.heartColum);
          this.heartColum.setOption(this.heartOptions);
        });
      }
      if (index == 4) {
        this.$nextTick(() => {
          this.breathtColum = this.$echarts.init(this.$refs.breathtColum);
          this.breathtColum.setOption(this.breathOptions);
        });
      }
    },
    initChart() {
      this.barColum = this.$echarts.init(this.$refs.barChart);
      this.barColum.setOption(this.barOptions);
    },
    jhChangeTime() {
      this.getInfo();
    },
    dailyChangeTime() {
      this.barActiveIndex = 0;

      this.getHealthDailyDataV2();
    },
    getInfo() {
      GetSOSWorkOrder({ day: this.timeData2 }).then((res) => {
        this.sosWork = res.result;
        this.sosWorkSuccess = res.result.filter((item) => {
          return item.status == 2;
        });
      });
      GetWaterWorkOrder({ day: this.timeData2 }).then((res) => {
        this.waterWork = res.result;
        this.waterWorkSuccess = res.result.filter((item) => {
          return item.status == 2;
        });
      });
      GetSmokeWorkOrder({ day: this.timeData2 }).then((res) => {
        this.smokesWork = res.result;
        this.smokesWorkSuccess = res.result.filter((item) => {
          return item.status == 2;
        });
      });
      GetGasWorkOrder({ day: this.timeData2 }).then((res) => {
        this.gasWork = res.result;
        this.gasWorkSuccess = res.result.filter((item) => {
          return item.status == 2;
        });
      });
    },

    initChart2() {
      this.showLine2 = false;
      this.$nextTick(() => {
        this.statusColum = this.$echarts.init(this.$refs.statusBar);

        CountSOSWorkOrderOfDays({ days: this.day }).then((res) => {
          let data = res.result;
          this.statusOptions.series[0].data = data.map((item) => {
            return item.item2;
          });

          this.statusOptions.xAxis.data = data.map((item) => {
            return item.item1;
          });
          this.statusColum.setOption(this.statusOptions);

          /* CountWaterWorkOrderOfDays({ days: this.day }).then((res) => {
            let data = res.result;
            this.statusOptions.series[1].data = data.map((item) => {
              return item.item2;
            });
            CountSmokeWorkOrderOfDays({ days: this.day }).then((res) => {
              let data = res.result;
              this.statusOptions.series[2].data = data.map((item) => {
                return item.item2;
              });
              CountGasWorkOrderOfDays({ days: this.day }).then((res) => {
                let data = res.result;
                this.statusOptions.series[3].data = data.map((item) => {
                  return item.item2;
                });

                this.statusColum.setOption(this.statusOptions);
              });
            });
          });
          */
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.statusBoxColor1 {
  border: 1px solid #fd500a;
  color: #fd500a;
}
.statusBoxColor2 {
  border: 1px solid #03ad82;
  color: #03ad82;
}
.statusBoxColor3 {
  border: 1px solid #ffc800;
  color: #ffc800;
}
.deviceList {
  display: flex;
  margin-top: 20px;
}
.deviceItem {
  width: 251px;
  height: 110px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
}
.devicebg1 {
  background: url(../../assets/img/homeGuardianship/bg_device.png) no-repeat;
  background-size: contain;
}
.devicebg2 {
  background: url(../../assets/img/homeGuardianship/bg_device2.png) no-repeat;
  background-size: contain;
}
.devicebg3 {
  background: url(../../assets/img/homeGuardianship/bg_device3.png) no-repeat;
  background-size: contain;
}
.devicebg4 {
  background: url(../../assets/img/homeGuardianship/bg_device4.png) no-repeat;
  background-size: contain;
}
.deviceIcon {
  width: 64px;
  height: 64px;
}
.deviceIcon2 {
  width: 46px;
  height: 46px;
}
.leftTitle {
  font-size: 20px;
  font-weight: 400;
  color: #f83e04;
  margin-bottom: 10px;
}
.countNum {
  font-size: 31px;
  color: #f83e04;
  margin-right: 5px;
}
.countUnit {
  font-size: 18px;
  font-weight: 400;
  color: #f83e04;
}

.leftColor p {
  color: #3e7a72 !important;
}
.leftColor span {
  color: #3e7a72 !important;
}

.leftColor2 p {
  color: #0e4eea !important;
}
.leftColor2 span {
  color: #0e4eea !important;
}
.leftColor3 p {
  color: #1473db !important;
}
.leftColor3 span {
  color: #1473db !important;
}
.timeBox {
  margin-bottom: 10px;
  /deep/.el-input__inner {
    background: none !important;
    border-color: #2f69e6 !important;
    color: #2f69e6 !important;
  }
  /deep/.el-input__inner::placeholder {
    color: #2f69e6 !important;
  }
  /deep/.el-input__prefix {
    color: #2f69e6;
  }
}

.earlyList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.earlyItme {
  margin-bottom: 20px;
  display: flex;
  width: 320px;
  min-height: 60px;
  background: #ffffff;
  margin-right: 50px;
  box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.08);
  border-radius: 5px 5px 5px 5px;
  .earlyBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }
  .earlyBoxR {
    border-right: 2px dashed #999999;
  }
}

.earlyItme2 {
  margin-bottom: 20px;
  width: 205px;
  min-height: 60px;
  margin-right: 50px;
  background: #ffffff;
  box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.08);
  border-radius: 5px 5px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  .earlyBox2 {
    display: flex;
  }
}

.earlyIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.earlyColor {
  background: #fce0e7;
}
.earlyColor2 {
  background: #d8fffd;
}
.earlyColor3 {
  background: #ced7fc;
}
.earlyColor4 {
  background: #ffd9d9;
}

.earlyImg {
  width: 18px;
  height: 18px;
}
.earlyTxt {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 2px;
}
.earlyLabel {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}
.safeList {
  display: flex;
  margin-top: 20px;
}
.safeItem {
  width: 154px;
  min-height: 60px;
  background: #ffffff;
  box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.08);
  border-radius: 5px 5px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.safeIcon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.barTitle {
  display: flex;
  div {
    margin-right: 45px;
    cursor: pointer;
    font-size: 16px;
    color: #000000;
    padding-bottom: 5px;
  }
  .barActive {
    font-size: 16px;
    font-weight: 400;
    color: #2f69e6;
    border-bottom: 2px solid #2f69e6;
  }
}
.barBox {
  padding: 20px;
}
.barChart {
  width: 100%;
  height: 450px;
}
.healthBox {
  position: relative;
}
.videoBox {
  background: url(../../assets/img/lease/leftBg.png) no-repeat;
  background-size: contain;
  width: 174px;
  height: 54px;
  position: absolute;
  top: 97px;
  right: -45px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  cursor: pointer;
}
.videoIcon {
  width: 24px;
  height: 24px;
}
.videoTxt {
  font-size: 24px;
  font-weight: 400;
  color: #0051ff;
  line-height: 24px;
  margin-left: 10px;
}
</style>
